import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { PICKUP_POINTS, ROUTE_POINTS } from './helper';
import utils from 'resources/utils';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import { hide_loader, show_loader } from 'actions/app';

const usePreLogin = () => {
	const dispatch = useDispatch();
	const [pickup_point, set_pickup_point] = useState(PICKUP_POINTS.pickup_points[0]);
	const [drop_point, set_drop_points] = useState(PICKUP_POINTS.pickup_points[0].drop_points[0]);
	const [show_circle, set_show_circle] = useState(false);
	const [map_data, set_map_data] = useState();
	const [selected_point, set_selected_point] = useState(5);
	const [selected_rider, set_selected_rider] = useState(1);
	const [routes_drop_points, set_routes_drop_points] = useState();
	const [button, set_button] = useState('');
	const [create_route, set_create_route] = useState(null);
	const [show_marker, set_show_marker] = useState(false);
	useEffect(() => {
		fetch_location_from_ip();
	}, []);

	useEffect(() => {
		fetch_partners_by_sectors();
	}, [pickup_point, drop_point]);

	useEffect(() => {
		if (button == 'Optimize Routes') {
			generate_random_routes_points(5, 600, 200);
		}
	}, [button]);

	const generate_random_point = (lat, lng, angle, distance) => {
		let earth_radius = 6378137;

		let latRad = (lat * Math.PI) / 180;
		let lngRad = (lng * Math.PI) / 180;

		let angleRad = (angle * Math.PI) / 180;

		let deltaY = distance * Math.cos(angleRad);

		let deltaX = distance * Math.sin(angleRad);

		let deltaLat = (deltaY / earth_radius) * (180 / Math.PI);

		let deltaLng = (deltaX / (earth_radius * Math.cos(latRad))) * (180 / Math.PI);

		let newLat = lat + deltaLat;
		let newLng = lng + deltaLng;

		return [newLat, newLng];
	};

	const fetch_partners_by_sectors = () => {
		const pick = _.filter(PICKUP_POINTS.pickup_points, (item) => item.value === pickup_point.value)[0];
		const drop = _.filter(pick?.drop_points, (item) => item.value === drop_point.value)[0];
		const partners =
			pickup_point.value == 0
				? ['Zomato', 'Porter', 'Shadowfax', 'Shiprocket', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge']
				: drop?.delivery_partners;
		const partner_info = {
			'5KM': { center: [pickup_point.coords.latitude, pickup_point.coords.longitude], radius: 600, delay: 0, angle: 0, text: 5 },
			'7KM': { center: [pickup_point.coords.latitude, pickup_point.coords.longitude], radius: 800, delay: 3000, angle: 60, text: 9 },
			'10KM': { center: [pickup_point.coords.latitude, pickup_point.coords.longitude], radius: 1000, delay: 6000, angle: 130, text: 11 },
		};

		const selectRandomPartners = (partners, angle, distance, key, text) => {
			const num_partners = Math.floor(Math.random() * 1) + 2;
			const shuffled_partners = partners?.sort(() => 0.5 - Math.random());
			return shuffled_partners.splice(0, num_partners).map((name, index) => ({
				name,
				center: generate_random_point(
					pickup_point.coords.latitude,
					pickup_point.coords.longitude,
					index == 1 ? (key == '5KM' ? 180 : key == '7KM' ? 260 : key == '10KM' ? 300 : angle) : angle,
					index == 0 ? (key == '5KM' ? 200 : distance - 100) : distance - 100,
				),
				text: `${index == 1 ? text + 2 : text} min away`,
			}));
		};

		const updated_partner_info = { ...partner_info };
		const _partners = _.cloneDeep(partners);
		for (const location in updated_partner_info) {
			updated_partner_info[location].partners = selectRandomPartners(
				_partners,
				updated_partner_info[location].angle,
				updated_partner_info[location].radius,
				location,
				updated_partner_info[location].text,
			);
		}
		set_map_data(updated_partner_info);
	};

	const handle_fetch_partner = () => {
		set_show_circle(true);
		let pickup_track = pickup_point?.title;
		let drop_track = drop_point?.title;
		if (pickup_point.value == 0) {
			pickup_track = [pickup_point.coords.latitude, pickup_point.coords.longitude];
			drop_track = [drop_point.coords.latitude, drop_point.coords.longitude];
		}
		utils.track_event_for_analytics(`${constant.TRACKING_EVENTS.SEARCH_PARTNERS}_pickup_${pickup_track}_drop_${drop_track}`);
	};

	const fetch_location_from_ip = async (fetch_location_click = false) => {
		const response = await fetch('https://ipapi.co/json/');
		const json = await response.json();
		const { latitude, longitude } = json;
		if (latitude && longitude) {
			if (!fetch_location_click) {
				let closest_pickup = null;
				let min_distance = Infinity;
				for (const pickup of PICKUP_POINTS.pickup_points) {
					const pick_lat = pickup.coords.latitude;
					const pick_lng = pickup.coords.longitude;
					const distance = utils.get_coords_aerial_distance(latitude, longitude, pick_lat, pick_lng);
					if (distance < min_distance) {
						min_distance = distance;
						closest_pickup = pickup;
					}
				}
				set_pickup_point(closest_pickup);
				set_drop_points(closest_pickup.drop_points[Math.floor(Math.random() * closest_pickup.drop_points.length)]);
			} else {
				const random_drop_points = one_random_drop_points(latitude, longitude);
				let pickup = {
					value: 0,
					coords: {
						latitude: latitude,
						longitude: longitude,
					},
					drop_points: [
						{
							value: 0,
							coords: {
								latitude: random_drop_points[0],
								longitude: random_drop_points[1],
							},
						},
					],
				};

				set_pickup_point(pickup);
				set_drop_points(pickup.drop_points[0]);
			}
		} else {
			set_pickup_point(PICKUP_POINTS.pickup_points[0]);
			set_drop_points(PICKUP_POINTS.pickup_points[0].drop_points[Math.floor(Math.random() * PICKUP_POINTS.pickup_points[0].drop_points.length)]);
		}
	};

	const one_random_drop_points = (lat, lng) => {
		const latitude = lat;
		const longitude = lng;
		const lat_factor = 110.574; // 1 degree latitude ~= 110.574 km
		const lon_factor = 111.32 * Math.cos(to_radian(latitude)); // 1 degree longitude ~= 111.32 km at equator

		const points = random_lat_longs(latitude, longitude, 2, lat_factor, lon_factor);

		return points;
	};
	const to_radians = (degrees) => {
		return (degrees * Math.PI) / 180;
	};

	const generate_random_routes_points = (num_points, radius, minDistance) => {
		set_selected_point(num_points);
		const rider = _.filter(ROUTE_POINTS, (val) => val.point === num_points)[0]?.riders;
		set_selected_rider(rider[0]);

		function toDegrees(radians) {
			return (radians * 180) / Math.PI;
		}

		function generateRandomBearing(minBearing, maxBearing) {
			return Math.random() * (maxBearing - minBearing) + minBearing;
		}

		function generateRandomDistance(radius) {
			return Math.random() * radius;
		}

		function haversineDistance(lat1, lon1, lat2, lon2) {
			const R = 6371000; // Radius of the Earth in meters
			const dLat = to_radians(lat2 - lat1);
			const dLon = to_radians(lon2 - lon1);
			const a =
				Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(to_radians(lat1)) * Math.cos(to_radians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
			const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			return R * c; // Distance in meters
		}

		function generateRandomPoint(lat, lon, radius, bearing) {
			const R = 6371000; // Radius of the Earth in meters
			const d = generateRandomDistance(radius) / R; // Convert radius to radians
			const brng = to_radians(bearing);

			const lat1 = to_radians(lat);
			const lon1 = to_radians(lon);

			const lat2 = Math.asin(Math.sin(lat1) * Math.cos(d) + Math.cos(lat1) * Math.sin(d) * Math.cos(brng));
			const lon2 = lon1 + Math.atan2(Math.sin(brng) * Math.sin(d) * Math.cos(lat1), Math.cos(d) - Math.sin(lat1) * Math.sin(lat2));

			return [toDegrees(lat2), toDegrees(lon2)];
		}

		function generatePoints(centerLat, centerLon, radius, bearingRanges, numPoints, minDistance, maxRetries = 1000) {
			const totalRanges = bearingRanges.length;
			const points = [];
			const pointsPerRange = Math.floor(numPoints / totalRanges);
			const remainderPoints = numPoints % totalRanges;

			for (let i = 0; i < totalRanges; i++) {
				const bearingRange = bearingRanges[i];
				const pointsInThisRange = pointsPerRange + (i < remainderPoints ? 1 : 0);

				for (let j = 0; j < pointsInThisRange; j++) {
					let point;
					let isValidPoint = false;
					let retries = 0;

					while (!isValidPoint && retries < maxRetries) {
						const bearing = generateRandomBearing(bearingRange[0], bearingRange[1]);
						point = generateRandomPoint(centerLat, centerLon, radius, bearing);

						const distanceToCenter = haversineDistance(centerLat, centerLon, point[0], point[1]);
						if (distanceToCenter < minDistance) {
							retries++;
							continue;
						}

						isValidPoint = points.every(
							(existingPoint) => haversineDistance(existingPoint[0], existingPoint[1], point[0], point[1]) >= minDistance,
						);

						retries++;
					}

					if (isValidPoint) {
						points.push(point);
					}
				}
			}

			return points;
		}

		const bearingRanges = [
			[0, 120],
			[121, 240],
			[241, 359],
		];
		const drop_points = generatePoints(pickup_point.coords.latitude, pickup_point.coords.longitude, radius, bearingRanges, num_points, minDistance);

		function convertArrayToObjectArray(coords) {
			return coords.map((coord, index) => {
				return {
					latitude: coord[0],
					longitude: coord[1],
					index: index,
				};
			});
		}

		const coords_object_array = convertArrayToObjectArray(drop_points);
		set_routes_drop_points(coords_object_array);
	};

	// Function to generate a random point within a specified radius
	const random_lat_longs = (latitude, longitude, radius, lat_factor, lon_factor) => {
		const r = Math.random();
		const theta = Math.random() * 2 * Math.PI;
		const dLat = (r * radius) / lat_factor;
		const dLon = (r * radius) / lon_factor;
		const latOffset = dLat * Math.cos(theta);
		const lonOffset = dLon * Math.sin(theta);
		const newLat = latitude + latOffset;
		const newLon = longitude + lonOffset;
		return [newLat, newLon];
	};

	// Function to convert degrees to radians
	const to_radian = (degrees) => {
		return degrees * (Math.PI / 180);
	};

	const handle_create_routes = async () => {
		utils.track_event_for_analytics(`${constant.TRACKING_EVENTS.OPT_CREATE_ROUTES}_deliveries_${selected_point}_riders_${selected_rider}`);

		function calculateBearing(lat1, lon1, lat2, lon2) {
			const φ1 = to_radians(lat1);
			const φ2 = to_radians(lat2);
			const Δλ = to_radians(lon2 - lon1);

			const y = Math.sin(Δλ) * Math.cos(φ2);
			const x = Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);
			const θ = Math.atan2(y, x);

			const bearing = ((θ * 180) / Math.PI + 360) % 360; // Convert to degrees and normalize
			return bearing;
		}

		function assignRoutes(centerLat, centerLon, coords, routeType) {
			const routes = [[], [], []];

			if (routeType === 1) {
				routes[0] = coords.map((coord, index) => ({ ...coord, index }));
			} else {
				for (const coord of coords) {
					const bearing = calculateBearing(centerLat, centerLon, coord.latitude, coord.longitude);

					if (routeType === 2) {
						if (bearing >= 0 && bearing <= 180) {
							routes[0].push({ ...coord, index: routes[0].length });
						} else {
							routes[1].push({ ...coord, index: routes[1].length });
						}
					} else if (routeType === 3) {
						if (bearing >= 0 && bearing <= 120) {
							routes[0].push({ ...coord, index: routes[0].length });
						} else if (bearing > 120 && bearing <= 240) {
							routes[1].push({ ...coord, index: routes[1].length });
						} else {
							routes[2].push({ ...coord, index: routes[2].length });
						}
					}
				}
			}
			if (routeType === 3 && coords.length == 5) {
				routes[2].push({ latitude: centerLat, longitude: centerLon, index: routes[2].length });
			}
			return routes.filter((route) => route.length > 0);
		}
		const new_routs_chunks = assignRoutes(pickup_point.coords.latitude, pickup_point.coords.longitude, routes_drop_points, selected_rider);

		let create_route_data = [];
		dispatch(show_loader());
		set_show_marker(false);
		for (const route of new_routs_chunks) {
			const data = {
				points: route.map((coords) => ({
					ref_id: uuidv4(),
					latitude: coords.latitude,
					longitude: coords.longitude,
				})),
			};
			try {
				const res = await api_requests.public_create_routes(uuidv4(), data);
				create_route_data.push(res.data);
			} catch (err) {
				console.log(err);
			}
		}
		dispatch(hide_loader());

		setTimeout(() => {
			set_show_marker(true);
		}, 3000);

		if (!_.isEmpty(create_route_data)) {
			set_create_route(create_route_data);
			const transformed_data = create_route_data.flatMap(({ points }, index) => {
				if (index == 2 && selected_point == 5 && selected_rider == 3) {
					points.splice(1, 1);
				}
				return points.map((point, key) => ({
					latitude: point.latitude,
					longitude: point.longitude,
					sequence: key + 1,
				}));
			});
			set_routes_drop_points(transformed_data);
		}
	};

	return {
		pickup_point,
		drop_point,
		show_circle,
		map_data,
		show_marker,
		create_route,
		routes_drop_points,
		button,
		selected_point,
		selected_rider,
		set_map_data,
		set_pickup_point,
		set_drop_points,
		set_selected_point,
		set_selected_rider,
		handle_fetch_partner,
		generate_random_routes_points,
		set_button,
		handle_create_routes,
		set_create_route,
		set_show_circle,
		fetch_location_from_ip,
		generate_random_point,
	};
};

export default usePreLogin;
