import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ImageLinks from 'assets/images/ImageLinks';
import RemoveIcon from '@material-ui/icons/Remove';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import './style.css';
import { NAVBAR_BOTTOM_ITEMS, NAVBAR_ITEMS, SEARCH_BY_OPTIONS } from './helper';
import { Link, useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { login_failed } from 'actions/app';
import { empty_alerts } from 'actions/alerts';
import _ from 'lodash';
import api_requests from 'resources/api_requests';
import Menu from '@material-ui/core/Menu';
import SearchBarContent from './SearchBarContent';
import Support from 'modules/modals/Support';
import { toggle_notif_modal, toggle_support_modal } from 'actions/uiUpdates';
import NotificationsListing from 'modules/modals/NotificationsListing/NotificationsListing';
import ConfirmClose from 'modules/modals/ConfirmClose';
import SwitchBizModal from 'modules/modals/SwitchBizModal/SwitchBizModal';
import Text from 'modules/Text';
import { useStyles } from './styles';
import { Badge, Divider } from '@material-ui/core';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import { ReactComponent as SettingsIcon } from '../../assets/images/icons/settings.svg';
import { ReactComponent as WalletIcon } from '../../assets/images/icons/wallet-selected.svg';
import { ReactComponent as BulbIcon } from '../../assets/images/icons/help_bulb.svg';
import { ReactComponent as SupportIcon } from '../../assets/images/icons/support_chat.svg';
import { ReactComponent as NotificationIcon } from '../../assets/images/icons/notification-bell.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search_outlined.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/icons/logout-purple.svg';
import { ReactComponent as UpDownIcon } from '../../assets/images/icons/up_down_arrow_circle_light_purple.svg';
import constant from 'resources/constant';
import utils from 'resources/utils';

const StyledMenuItem = withStyles((theme) => ({
	root: {
		cursor: 'pointer',
	},
}))(MenuItem);

const Sidebar = ({ set_rider_data, open, setOpen }) => {
	const { user_details, user_teams, business_accounts, selected_business_id: business_id_information, app_configs } = useSelector(({ app }) => app);
	const { support_modal_toggle, notif_modal_toggle } = useSelector(({ uiUpdates }) => uiUpdates);
	const { notif_count, alert_count } = useSelector(({ alerts }) => alerts);
	const theme = useTheme();
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const [search_bar_open, set_search_bar_open] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState('');
	const [search_by, set_search_by] = useState(SEARCH_BY_OPTIONS[0].value);
	const [search_text, set_search_text] = useState('');
	const [search_rider_text, set_search_rider_text] = useState('');
	const [search_riders, set_search_riders] = useState([]);
	const [show_support_modal, set_show_support_modal] = useState(false);
	const [show_notif_modal, set_show_notif_modal] = useState(false);
	const [show_business_switch_modal, set_show_business_switch_modal] = useState(false);
	const [is_kyc, set_is_kyc] = useState(false);
	const [is_discover, set_is_discover] = useState(false);
	const [show_logout_selected_icon, set_show_logout_selected_icon] = useState(false);
	const [notif_tab, set_notif_tab] = useState(null);
	const [show_logout_list, set_show_logout_list] = useState(false);
	const [show_logout_all_confirm, set_show_logout_all_confirm] = useState(false);
	const [show_pwp_bar, set_show_pwp_bar] = useState(false);
	const [mongo_charts, set_mongo_charts] = useState([]);
	const logout_button_ref = useRef(null);

	useEffect(() => {
		get_mongo_charts();
	}, []);

	useEffect(() => {
		if (support_modal_toggle?.show_modal) {
			handle_list_item_click('', 'support');
			set_show_support_modal(true);

			if (support_modal_toggle?.mode === 'kyc') {
				set_is_kyc(true);
			}
			if (support_modal_toggle?.mode === 'network_discover') {
				set_is_discover(true);
			}
			dispatch(toggle_support_modal({}));
		}
	}, [support_modal_toggle]);

	useEffect(() => {
		if (notif_modal_toggle?.open) {
			set_notif_tab(notif_modal_toggle?.is_alert ? 0 : 1);
			get_notif_list();
			dispatch(toggle_notif_modal({}));
		}
	}, [notif_modal_toggle]);

	useEffect(() => {
		if (search_bar_open) {
			set_show_business_switch_modal(false);
			set_show_notif_modal(false);
		}
	}, [search_bar_open]);

	useEffect(() => {
		if (show_business_switch_modal) {
			set_search_bar_open(false);
			set_show_notif_modal(false);
		}
	}, [show_business_switch_modal]);

	useEffect(() => {
		if (show_notif_modal) {
			set_search_bar_open(false);
			set_show_business_switch_modal(false);
		}
	}, [show_notif_modal]);

	useEffect(() => {
		get_selected_nav_value();
		set_show_business_switch_modal(false);
		set_show_notif_modal(false);
		set_search_bar_open(false);
	}, [history.location.pathname]);

	useEffect(() => {
		const biz_config = app_configs.business_configs[business_id_information];
		const parent_id = _.get(app_configs, `business_configs.${business_id_information}.parent_id`);
		const parent_config_whitelabel = _.get(app_configs, `parent_configs.${parent_id}.whitelabeling`, {});
		if (!_.isEmpty(biz_config?.whitelabeling) || !_.isEmpty(parent_config_whitelabel)) {
			set_show_pwp_bar(true);
			theme.colors.sideNavPrimary = _.get(
				biz_config,
				'whitelabeling.primary_color',
				_.get(parent_config_whitelabel, 'primary_color', theme.colors.primary),
			);
			theme.colors.sideNavSecondary = _.get(
				biz_config,
				'whitelabeling.secondary_color',
				_.get(parent_config_whitelabel, 'secondary_color', theme.colors.lightPurple),
			);
			theme.colors.sideNavText = _.get(biz_config, 'whitelabeling.text_color', _.get(parent_config_whitelabel, 'text_color', theme.colors.white));
			theme.colors.sideNavHover = _.get(biz_config, 'whitelabeling.hover_color', _.get(parent_config_whitelabel, 'hover_color', '#684CA9'));
			window.document.documentElement.style.setProperty(
				'--main-menu-secondary',
				_.get(biz_config, 'whitelabeling.secondary_color', _.get(parent_config_whitelabel, 'secondary_color', theme.colors.lightPurple)),
			);
			window.document.documentElement.style.setProperty(
				'--main-side-nav-primary',
				_.get(biz_config, 'whitelabeling.primary_color', _.get(parent_config_whitelabel, 'primary_color', theme.colors.primary)),
			);
			window.document.documentElement.style.setProperty(
				'--main-side-nav-hover',
				_.get(biz_config, 'whitelabeling.hover_color', _.get(parent_config_whitelabel, 'hover_color', '#684CA9')),
			);
			window.document.documentElement.style.setProperty(
				'--main-side-nav-text',
				_.get(biz_config, 'whitelabeling.text_color', _.get(parent_config_whitelabel, 'text_color', theme.colors.white)),
			);
		} else {
			theme.colors.sideNavPrimary = theme.colors.primary;
			theme.colors.sideNavText = theme.colors.white;
			window.document.documentElement.style.setProperty('--main-menu-secondary', theme.colors.lightPurple);
			window.document.documentElement.style.setProperty('--main-side-nav-primary', theme.colors.primary);
			window.document.documentElement.style.setProperty('--main-side-nav-hover', '#684CA9');
			window.document.documentElement.style.setProperty('--main-side-nav-text', theme.colors.white);
		}
	}, [app_configs, business_id_information]);

	const get_selected_nav_value = () => {
		const current_route = _.get(history, 'location.pathname', '');
		const active_item = _.filter(NAVBAR_ITEMS, (item, key) => {
			return item.routeLink == current_route;
		});
		const active_bottom_item = _.filter(NAVBAR_BOTTOM_ITEMS, (item, key) => {
			return item.routeLink == current_route;
		});
		if (current_route == '/roster') {
			setSelectedIndex(NAVBAR_ITEMS.RIDERS.id);
			return;
		}
		if (active_item.length > 0) {
			setSelectedIndex(active_item[0].id);
			return;
		} else {
			if (active_bottom_item.length > 0) {
				setSelectedIndex(active_bottom_item[0].id);
				return;
			}
		}
		if (current_route == Routes.HOME.path) {
			setSelectedIndex(NAVBAR_ITEMS.DASHBOARD.id);
			return;
		}
		setSelectedIndex(null);
	};

	useEffect(() => {
		if (_.isEmpty(search_rider_text.trim())) {
			set_search_riders([]);
			return;
		}
		api_requests
			.search_rider_without_zone({
				search: search_rider_text.trim(),
				is_active: true,
			})
			.then((res) => {
				set_search_riders(res.data);
			});
	}, [search_rider_text]);

	const handle_get_rider_details = async (rider_id) => {
		try {
			const res = await api_requests.get_rider_details(rider_id);
			if (res.data) {
				set_rider_data(res.data);
				set_search_rider_text('');
				set_search_bar_open(false);
				set_search_by(SEARCH_BY_OPTIONS[0].value);
			}
		} catch (err) {}
	};

	const logout = () => {
		dispatch(login_failed({ logout: true }));
		dispatch(empty_alerts());
	};

	const handle_logout_all = async () => {
		try {
			const res = await api_requests.logout_all_user({ all: true });
			logout();
		} catch (err) {}
	};

	const handle_drawer_open = () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.NAV_COLLAPSE);
		setOpen(true);
		set_search_text('');
		set_search_bar_open(false);
		set_search_by(SEARCH_BY_OPTIONS[0].value);
	};

	const handle_drawer_close = () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.NAV_COLLAPSE);
		setOpen(false);
		set_search_text('');
		set_search_bar_open(false);
		set_search_by(SEARCH_BY_OPTIONS[0].value);
	};

	const handle_list_item_click = (event, index) => {
		if (index !== 'notifications') {
			set_show_notif_modal(false);
		}
		if (index !== 'biz_switch') {
			set_show_business_switch_modal(false);
		}
		setSelectedIndex(index);
	};

	const handle_search_value = (val) => {
		set_search_by(val);
	};

	const handle_outside_click = () => {
		set_search_by(SEARCH_BY_OPTIONS[0].value);
		set_search_bar_open(false);
		set_search_text('');
	};

	const handle_reference_id_details = (e) => {
		e.preventDefault();
		if (search_by === 'reference_id') {
			history.push({ pathname: Routes.ALL_ORDERS.path, reference_id: search_text.trim() });
			set_search_text('');
			set_search_bar_open(false);
			set_search_by(SEARCH_BY_OPTIONS[0].value);
		}
	};

	const get_notif_list = async () => {
		if (user_teams.length === 0) return;
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.NAV_NOTIFICATION);
		set_show_notif_modal(true);
	};

	const get_mongo_charts = async () => {
		try {
			const res = await api_requests.get_mongo_charts();
			set_mongo_charts(_.get(res, 'data.items', []));
		} catch (err) {}
	};

	const icon_alignment = open ? 'list-item-icon-alignment' : 'list-item-icon-alignment-close-drawer';
	const selected_business_information = business_accounts.filter((business_account_info) => business_account_info.id === business_id_information)[0];
	const biz_config = app_configs.business_configs[business_id_information];
	const parent_id = _.get(app_configs, `business_configs.${business_id_information}.parent_id`);
	const parent_config_whitelabel = _.get(app_configs, `parent_configs.${parent_id}.whitelabeling`, {});

	return (
		<div className={`${classes.root} ${classes.content} menulist-item`}>
			<CssBaseline />
			<AppBar
				position='fixed'
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
				style={open ? { display: 'none' } : { width: 72, left: 0, marginTop: 20 }}>
				<Toolbar style={{ flexDirection: 'column', backgroundColor: theme.colors.sideNavPrimary }}>
					<div color='inherit' aria-label='open drawer' style={{ paddingTop: 4 }}>
						<SearchIcon
							className='search_icon_navbar'
							width={'20px'}
							height={'20px'}
							src={ImageLinks.search_outlined}
							alt='search'
							onClick={() => {
								utils.track_event_for_analytics(constant.TRACKING_EVENTS.NAV_SEARCH);
								set_search_bar_open(true);
							}}
						/>
					</div>
					<Badge
						overlap='circular'
						badgeContent={notif_count + alert_count}
						color='error'
						className='cursor-pointer'
						onClick={(event) => {
							get_notif_list();
							handle_list_item_click(event, NAVBAR_BOTTOM_ITEMS.NOTIFICATIONS.id);
						}}>
						<div className='icons_wrap'>
							<NotificationIcon
								className={'icon_active_style'}
								style={{ height: 24, width: 24 }}
								onClick={(event) => {
									get_notif_list();
									handle_list_item_click(event, NAVBAR_BOTTOM_ITEMS.NOTIFICATIONS.id);
								}}
							/>
						</div>
					</Badge>
				</Toolbar>
			</AppBar>

			{search_bar_open && (
				<SearchBarContent
					set_search_bar_open={set_search_bar_open}
					search_by={search_by}
					handle_search_value={handle_search_value}
					search_text={search_text}
					set_search_text={set_search_text}
					handle_reference_id_details={handle_reference_id_details}
					search_rider_text={search_rider_text}
					set_search_rider_text={set_search_rider_text}
					search_riders={search_riders}
					handle_get_rider_details={handle_get_rider_details}
					outside_click={handle_outside_click}
					open={open}
				/>
			)}
			{open ? (
				<div className='expanded_drawer_arrow' onClick={handle_drawer_close}>
					<ChevronLeftIcon />
				</div>
			) : (
				<div className='expanded_drawer_arrow' style={{ left: '60px' }} onClick={handle_drawer_open}>
					<ChevronRightIcon />
				</div>
			)}
			<Drawer
				variant='permanent'
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				style={{ position: 'relative' }}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}>
				<div className={classes.toolbar} style={{ background: theme.colors.sideNavPrimary }}>
					{open && (
						<img
							src={
								_.isEmpty(_.get(biz_config, 'whitelabeling.brand_logo', '')) && _.isEmpty(_.get(parent_config_whitelabel, 'brand_logo', ''))
									? ImageLinks.logoFullWhite
									: _.isEmpty(_.get(biz_config, 'whitelabeling.brand_logo', ''))
									? _.get(parent_config_whitelabel, 'brand_logo', '')
									: biz_config.whitelabeling.brand_logo
							}
							height={
								_.isEmpty(_.get(biz_config, 'whitelabeling.brand_logo', '')) && _.isEmpty(_.get(parent_config_whitelabel, 'brand_logo', ''))
									? 32
									: 30
							}
							loading='lazy'
							style={{ maxWidth: 150 }}
							alt='company-white-logo'
						/>
					)}
					<div className='d-flex ' style={{ gap: 12 }}>
						<Badge
							className='cursor-pointer'
							overlap='circular'
							badgeContent={open ? notif_count + alert_count : 0}
							color='error'
							onClick={(event) => {
								get_notif_list();
								// handle_list_item_click(event, NAVBAR_BOTTOM_ITEMS.NOTIFICATIONS.id);
							}}>
							<div className={'icons_wrap'} style={{ height: 20, width: 20, padding: 0 }}>
								<NotificationIcon
									className={'icon_active_style'}
									style={{ height: 20, width: 20 }}
									onClick={(event) => {
										get_notif_list();
										// handle_list_item_click(event, NAVBAR_BOTTOM_ITEMS.NOTIFICATIONS.id);
									}}
								/>
							</div>
						</Badge>
						<SearchIcon
							className='search_icon_navbar'
							width={'20px'}
							height={'20px'}
							src={ImageLinks.search_outlined}
							alt='search'
							onClick={() => {
								utils.track_event_for_analytics(constant.TRACKING_EVENTS.NAV_SEARCH);
								set_search_bar_open(true);
							}}
						/>
					</div>
				</div>
				<List
					className={classes.sidebarBox}
					style={{ color: theme.colors.sideNavText, background: theme.colors.sideNavPrimary, paddingBottom: show_pwp_bar && open ? 12 : 0 }}>
					<div
						className={classes.top_navmenu_container}
						style={open ? { overflowX: 'hidden', overflowY: 'auto' } : { overflow: 'unset', marginTop: 12 }}>
						{_.map(NAVBAR_ITEMS, (item, key) => {
							if (item.id === 'networks' && constant.ENVIRONMENT === constant.env.PRODUCTION) return;
							if (item.id == 'analytics' && mongo_charts.length == 0) return;
							return (
								<>
									<Link
										to={{
											pathname: item.routeLink,
											state: { from_sidebar: true },
										}}
										className='link-customization'>
										<ListItem
											button
											key={item.id}
											selected={selectedIndex === item.id}
											onClick={(event) => handle_list_item_click(event, item.id)}>
											<ListItemIcon className={icon_alignment} style={{ marginLeft: open ? 0 : 3 }}>
												{item.image(selectedIndex === item.id ? 'icon_style' : 'icon_active_style')}
											</ListItemIcon>
											<ListItemText primary={item.text} />
										</ListItem>
									</Link>
									{selectedIndex === item.id &&
										item.SUB_MENU &&
										_.map(item.SUB_MENU, (item, index) => {
											return (
												<div
													onClick={() => history.push(item.routeLink)}
													className={
														history.location.pathname == item.routeLink
															? 'link-customization-child-active'
															: 'link-customization-child'
													}
													style={{ borderRadius: 4 }}>
													<StyledMenuItem>
														<ListItemIcon className='list-item-icon-alignment'>
															<RemoveIcon className='icon_active_style' style={{ color: theme.colors.side }} />
														</ListItemIcon>
														{open && <ListItemText primary={item.text} />}
													</StyledMenuItem>
												</div>
											);
										})}
								</>
							);
						})}
					</div>

					<div>
						<div className='d-flex justify-content-center align-items-center' style={{ gap: 20, flexDirection: open ? 'row' : 'column' }}>
							{open && (
								<div
									className={selectedIndex === NAVBAR_BOTTOM_ITEMS.SUPPORT.id ? 'icons_wrap_active' : 'icons_wrap'}
									onClick={(event) => {
										utils.track_event_for_analytics(constant.TRACKING_EVENTS.NAV_SUPPORT);
										handle_list_item_click(event, 'support');
										set_show_support_modal(true);
									}}>
									<BootstrapTooltip
										title={
											<Text semi style={{ fontSize: 12 }}>
												Support
											</Text>
										}
										is_white={true}
										placement='top'>
										<SupportIcon
											className={selectedIndex === NAVBAR_BOTTOM_ITEMS.SUPPORT.id ? 'icon_style' : 'icon_active_style'}
											style={{ height: 24, width: 24 }}
										/>
									</BootstrapTooltip>
								</div>
							)}
							{open && (
								<div
									className={'icons_wrap'}
									onClick={() => {
										utils.track_event_for_analytics(constant.TRACKING_EVENTS.NAV_HELP_CENTER);
										window.open('https://pidge.in/helpcenter/', '_blank');
									}}>
									<BootstrapTooltip
										title={
											<Text semi style={{ fontSize: 12 }}>
												Help
											</Text>
										}
										is_white={true}
										placement='top'>
										<BulbIcon className={'icon_active_style'} style={{ height: 22, width: 22 }} />
									</BootstrapTooltip>
								</div>
							)}
							{open && <div className={'divider_line'} style={{ width: 1, height: 30 }} />}
							<div
								className={selectedIndex === NAVBAR_BOTTOM_ITEMS.SETTINGS.id ? 'icons_wrap_active' : 'icons_wrap'}
								style={{ padding: 4 }}
								onClick={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.NAV_SETTING);
									history.push('/settings');
								}}>
								<BootstrapTooltip
									title={
										<Text semi style={{ fontSize: 12 }}>
											Settings
										</Text>
									}
									is_white={true}
									placement='top'>
									<SettingsIcon
										className={selectedIndex === NAVBAR_BOTTOM_ITEMS.SETTINGS.id ? 'icon_style' : 'icon_active_style'}
										style={{ height: 22, width: 22 }}
									/>
								</BootstrapTooltip>
							</div>
							<div
								className={selectedIndex === NAVBAR_BOTTOM_ITEMS.WALLET.id ? 'icons_wrap_active' : 'icons_wrap'}
								onClick={() => {
									utils.track_event_for_analytics(constant.TRACKING_EVENTS.NAV_WALLET);
									history.push('/my-wallet');
								}}>
								<BootstrapTooltip
									title={
										<Text semi style={{ fontSize: 12 }}>
											Billing
										</Text>
									}
									is_white={true}
									placement='top'>
									<WalletIcon
										className={selectedIndex === NAVBAR_BOTTOM_ITEMS.WALLET.id ? 'icon_style' : 'icon_active_style'}
										style={{ height: 24, width: 24 }}
									/>
								</BootstrapTooltip>
							</div>
						</div>

						<div className='business_details_wrap'>
							<div style={{ width: '100%', wordBreak: 'break-word', whiteSpace: 'normal' }}>
								<div
									className='d-flex justify-content-between align-items-center cursor-pointer'
									onClick={(event) => {
										set_show_business_switch_modal(true);
										handle_list_item_click(event, NAVBAR_BOTTOM_ITEMS.BIZ_SWITCH.id);
									}}>
									{open && (
										<Text semi>
											{_.get(selected_business_information, 'brand_name', 'Business Name')}{' '}
											<Text component={'span'} semi className={classes.user_sub_details}>
												({_.get(selected_business_information, 'id', 'Business ID')})
											</Text>
										</Text>
									)}
									<UpDownIcon className='icon_active_style' style={{ height: 20, width: 20, marginLeft: open ? 0 : 6, cursor: 'pointer' }} />
								</div>
							</div>

							<Divider className='divider_line' style={{ margin: '10px 0' }} />
							<div className='d-flex justify-content-between align-items-center' ref={logout_button_ref}>
								<div className='d-flex align-items-center'>
									<Avatar src={ImageLinks.user_profile} alt='user image' style={{ marginLeft: open ? 0 : -4 }} />
									{open && (
										<div style={{ marginLeft: 5 }}>
											<Text semi>{user_details?.name}</Text>
											<Text medium>({user_details.user_id})</Text>
										</div>
									)}
								</div>
								{open && (
									<div
										onClick={() => {
											set_show_logout_list(true);
										}}
										onMouseOut={() => set_show_logout_selected_icon(false)}
										onMouseOver={() => set_show_logout_selected_icon(true)}
										className={show_logout_selected_icon ? 'logout_wrap_active' : 'logout_wrap_inactive'}
										style={{
											borderRadius: '50%',
											height: 20,
											width: 20,
										}}>
										<BootstrapTooltip
											is_white={true}
											title={
												<Text semi style={{ fontSize: 12 }}>
													Logout
												</Text>
											}
											aria-label='logout'>
											<LogoutIcon
												className={show_logout_selected_icon ? 'icon_style' : 'icon_active_style'}
												style={{ height: 20, width: 20 }}
											/>
										</BootstrapTooltip>
									</div>
								)}
							</div>
						</div>
						<Menu
							anchorEl={logout_button_ref.current}
							open={show_logout_list}
							onClose={() => set_show_logout_list(false)}
							style={{ marginTop: -40, marginLeft: 60 }}>
							<MenuItem onClick={logout}>Logout</MenuItem>
							<MenuItem
								onClick={() => {
									set_show_logout_all_confirm(true);
									set_show_logout_list(false);
								}}>
								Logout Everywhere
							</MenuItem>
						</Menu>
					</div>
				</List>
				{show_pwp_bar && open && (
					<div
						className='d-flex justify-content-center align-items-center'
						style={{ height: 16, width: '100%', backgroundColor: theme.colors.lightPurple6, gap: 5, position: 'absolute', bottom: 0 }}>
						<Text semi style={{ fontSize: 11, color: theme.colors.primary }}>
							powered by
						</Text>
						<img src={ImageLinks.logo} height={11} width={25} />
					</div>
				)}
			</Drawer>
			{show_logout_all_confirm && (
				<ConfirmClose
					headerTitle='Logout Everywhere'
					title='This logs you out of Pidge from all the devices you’re logged in.'
					negative_btn_text='Cancel'
					show_negative_btn={true}
					positive_btn_text='Logout Everywhere'
					close={() => set_show_logout_all_confirm(false)}
					on_confirm={() => handle_logout_all()}
				/>
			)}
			{show_support_modal && (
				<Support
					is_kyc={is_kyc}
					is_discover={is_discover}
					close={() => {
						set_is_kyc(false);
						set_is_discover(false);
						set_show_support_modal(false);
						get_selected_nav_value();
					}}
				/>
			)}
			{show_notif_modal && (
				<NotificationsListing
					user_teams={user_teams}
					notif_tab={notif_tab}
					wrapper_styles={{ left: open ? 240 : 80 }}
					close={() => {
						get_selected_nav_value();
						set_show_notif_modal(false);
					}}
				/>
			)}
			{show_business_switch_modal && (
				<SwitchBizModal
					wrapper_styles={{ left: open ? 240 : 80 }}
					close={() => {
						get_selected_nav_value();
						set_show_business_switch_modal(false);
					}}
				/>
			)}
		</div>
	);
};

export default Sidebar;
