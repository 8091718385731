import React, { useEffect, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import FilterButton from 'modules/FilterButton';
import MongoFilters from './MongoFilters';
import _ from 'lodash';
import moment from 'moment';

const get_default_filters = (filter) => {
	let query = {};
	_.forEach(filter, (filter, index) => {
		if (filter.type == 'date_range' && !_.isEmpty(_.get(filter, 'default', {}))) {
			query[`${filter.key}`] = {
				$gte: new Date(moment().subtract(_.get(filter, 'default.amount', 7), _.get(filter, 'default.unit', 'days')).toISOString()),
				$lte: new Date(moment().toISOString()),
			};
		}
	});
	return query;
};

const MyDashboardEmbedComponent = ({ chart, token = '' }) => {
	const { chart_id, base_url, filter } = chart;

	const [show_filter_menu, set_show_filter_menu] = useState(false);
	const [applied_filters, set_applied_filters] = useState(get_default_filters(filter));

	useEffect(() => {
		const sdk = new ChartsEmbedSDK({ baseUrl: base_url });
		const dashboardEmbed = sdk.createChart({
			chartId: chart_id,
			height: '60vh',
			width: '35vw',
			getUserToken: () => {
				return token;
			},
			filter: { ...applied_filters },
			showAttribution: false,
		});

		dashboardEmbed.render(document.getElementById(`chart-container-${chart_id}`));
	}, [chart_id, token, applied_filters]);

	return (
		<>
			<div id={`chart-container-${chart_id}`} />
			<div>
				<FilterButton
					handle_click={() => {
						set_show_filter_menu(!show_filter_menu);
					}}
				/>
			</div>
			{show_filter_menu && (
				<MongoFilters
					close={() => set_show_filter_menu(false)}
					filters={filter}
					set_applied_filters={set_applied_filters}
					applied_filters={applied_filters}
				/>
			)}
		</>
	);
};

export default MyDashboardEmbedComponent;
