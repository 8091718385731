import React, { useMemo } from 'react';
import { InputAdornment, ListItem, makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import Text from 'modules/Text';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import Button from 'modules/Button';
import OutlinedTextField from 'modules/OutlinedTextField';
import Select from 'modules/Select';
import api_requests from 'resources/api_requests';
import { toast } from 'react-toastify';
import utils from 'resources/utils';

const useStyles = makeStyles(() => ({
	heading_container: { display: 'flex', justifyContent: 'space-between', marginBottom: 10 },
	heading: { color: theme.colors.primary, fontSize: 16, marginBottom: 8 },
	sub_heading: { color: theme.colors.darkGrey2, fontSize: 14 },
	info_container: { width: 470, backgroundColor: theme.colors.lightPurple10, borderRadius: 20, padding: 20, height: 640 },
	update: { borderRadius: 30, fontSize: 16, marginTop: 10, width: 100 },
	calls_box_text: { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 4 },
	box_container: { display: 'flex', justifyContent: 'space-between' },
	info_sub_heading: { fontSize: 16, color: theme.colors.primary, marginTop: 30 },
	calling_container: { display: 'flex', justifyContent: 'space-between', alignItems: 'start', height: 500, marginTop: 30 },
	info_heading: { fontSize: 18, color: theme.colors.lightPurple7 },
	mask_unmask_subText: { fontSize: 12, marginTop: 10, marginBottom: 10 },
	line: { borderTop: `1px solid ${theme.colors.lightGrey2}`, marginTop: 18, marginBottom: 18 },
	menu_item_class: {
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
		},
	},
	char_count_wrap: { position: 'absolute', right: 40, bottom: 8 },
	reattempt_button: {
		display: 'flex',
		width: '87.5px',
		padding: '6.667px',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: 30,
		border: `0.833px solid ${theme.colors.lightPurple6}`,
	},
	tips_text: { color: theme.colors.primary, fontSize: 10, marginLeft: 2 },
	reason_wrap: {
		marginTop: 14,
		marginBottom: 14,
		padding: 16,
		backgroundColor: theme.colors.white,
		borderRadius: 8,
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
		display: 'block',
	},
	left_wrap: { flex: 1, paddingLeft: 40, paddingRight: 40 },
	sender_receiver_wrap: {
		padding: '8px 10px',
		borderRadius: 4,
		backgroundColor: theme.colors.lightPink9,
		border: `1px solid ${theme.colors.lightPink4}`,
		marginTop: 3,
	},
	info_box_wrap: {
		padding: 12,
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
		border: `1px solid ${theme.colors.lightPink9}`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	infp_box_desc: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 4 },
	preview_wrap: { backgroundColor: theme.colors.white, borderRadius: 10, paddingBottom: 30, marginTop: 21 },
	preview_item_wrap: { borderRadius: 6, padding: 8, height: 38, marginTop: 8, display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	add_reason_text: { fontSize: 16, color: theme.colors.primary, textDecoration: 'underline', marginLeft: 6 },
}));

const drop = [
	{
		title: 'Call Verification',
		value: 'call_verification',
	},
	{
		title: 'OTP Verification',
		value: 'otp',
	},
];

const default_structure = [
	{
		key: '',
		value: '',
		modularity: {
			type: '',
		},
	},
];
const FailedDelivery = ({ failed_reasons, set_refresh }) => {
	const [reason_list, set_reason_list] = React.useState(_.isEmpty(failed_reasons.reasons) ? default_structure : failed_reasons.reasons);
	const classes = useStyles();

	const handle_change = (index, name, value) => {
		const _reason = _.cloneDeep(reason_list);
		_reason[index][name] = value;
		set_reason_list(_reason);
	};

	const handle_select_action = (val, index) => {
		const _reason = _.cloneDeep(reason_list);
		if (val === 'otp') {
			_reason[index].modularity = { type: val };
		} else {
			_reason[index].modularity = { type: val, min_call_to_originator: 0, min_call_to_receiver: 0, waiting_time: 0 };
		}
		set_reason_list(_reason);
	};

	const handle_add_reason = () => {
		const _reason = _.cloneDeep(reason_list);
		_reason.push(default_structure[0]);
		set_reason_list(_reason);
	};

	const handle_delete = (index) => {
		const _reason = _.cloneDeep(reason_list);
		_reason.splice(index, 1);
		set_reason_list(_reason);
	};

	const handle_update = async () => {
		let is_create_flow = true;
		const config_payload = {
			default: false,
			failed_reasons: {
				id: failed_reasons?.id || undefined,
				reasons: reason_list,
			},
			owner: {
				id: utils._retrieveData('owner_id'),
			},
		};
		if (failed_reasons?.id) {
			is_create_flow = false;
		}

		try {
			const response = is_create_flow
				? await api_requests.create_network_failed_reasons(-2, config_payload)
				: await api_requests.update_network_failed_reasons(-2, config_payload);
			toast.success('OK', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			set_refresh((prev) => !prev);
		} catch (err) {}
	};

	const is_data_updated = useMemo(() => {
		let value = false;
		const old_reasons = failed_reasons.reasons;
		const new_reasons = reason_list;

		if (old_reasons.length != new_reasons.length) {
			return true;
		}

		for (let i = 0; i < old_reasons.length; i++) {
			if (
				old_reasons[i].key != new_reasons[i].key ||
				old_reasons[i].value != new_reasons[i].value ||
				old_reasons[i].modularity.type != new_reasons[i].modularity.type ||
				(old_reasons[i].modularity.type === new_reasons[i].modularity.type &&
					new_reasons[i].modularity.type === 'call_verification' &&
					(old_reasons[i].modularity.min_call_to_originator != new_reasons[i].modularity.min_call_to_originator ||
						old_reasons[i].modularity.min_call_to_receiver != new_reasons[i].modularity.min_call_to_receiver ||
						old_reasons[i].modularity.waiting_time != new_reasons[i].modularity.waiting_time))
			) {
				value = true;
				break;
			}
		}
		return value;
	}, [reason_list, failed_reasons]);

	const btn_disabled = useMemo(() => {
		let disabled = false;
		for (let i = 0; i < reason_list.length; i++) {
			const element = reason_list[i];
			const is_call = element.modularity.type === 'call_verification';
			if (
				is_call &&
				(element.modularity.min_call_to_originator == 0 || element.modularity.min_call_to_receiver == 0 || element.modularity.waiting_time == 0)
			) {
				disabled = true;
				break;
			}
			if (_.isEmpty(element.key) || _.isEmpty(element.value) || element.value.length > 30 || _.isEmpty(element.modularity.type)) {
				disabled = true;
				break;
			}
		}
		if (!is_data_updated && !disabled) disabled = true;
		return disabled;
	}, [reason_list, is_data_updated]);

	return (
		<>
			<div style={{ position: 'relative' }}>
				<div className={classes.heading_container}>
					<Text bold className={classes.heading}>
						Failed Delivery
					</Text>
					<ListItem
						key={`tips`}
						button
						onClick={() => {}}
						style={{
							backgroundColor: theme.colors.lightPurple,
							marginRight: 0,
							width: 80,
							borderRadius: 5,
							display: 'flex',
							justifyContent: 'center',
							padding: '8px 8px',
						}}>
						<Text bold className={classes.tips_text}>
							Tips & Info
						</Text>
					</ListItem>
				</div>
				<div style={{ display: 'flex' }}>
					<div style={{ marginRight: 20, flex: '1' }}>
						<Text medium className={classes.sub_heading}>
							Enable verification of Failed delivery variety of POP options. All proof information is available against order for review
							real-time.
						</Text>
						<div className={classes.line}></div>

						<Text bold style={{ fontSize: 14 }}>
							Setup Return Criteria
						</Text>

						<div>
							{_.map(reason_list, (item, index) => (
								<div key={`reason_item_${index}`} className={classes.reason_wrap}>
									<div>
										<div className='d-flex'>
											<div style={{ flex: 1, borderRightWidth: 1, borderRight: `1px solid ${theme.colors.lightPink4}` }}>
												<OutlinedTextField
													type='text'
													value={item.key}
													textStyle={{ fontSize: 16, color: theme.colors.black }}
													name='key'
													onChange={(e) => {
														handle_change(index, e.target.name, e.target.value);
													}}
													label='Reason*'
													style={{ width: '90%' }}
												/>
												<div style={{ position: 'relative' }}>
													<OutlinedTextField
														type='text'
														multiline
														minRows={3}
														value={item.value}
														textStyle={{ fontSize: 16, color: theme.colors.black }}
														name='value'
														onChange={(e) => {
															handle_change(index, e.target.name, e.target.value);
														}}
														label='Text on screen*'
														style={{ marginTop: 18, width: '90%' }}
													/>

													<div className={classes.char_count_wrap}>
														<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
															<Text
																medium
																component={'span'}
																style={{
																	fontSize: 12,
																	color: item.value.length < 30 ? theme.colors.darkGrey2 : theme.colors.red,
																}}>
																{item.value.length}
															</Text>
															/30
														</Text>
													</div>
												</div>
											</div>

											<div className={classes.left_wrap}>
												<div className='align-items-center justify-content-between cursor-pointer'>
													<Text semi style={{ fontSize: 14 }}>
														Proof Type*
													</Text>
													{reason_list.length > 1 && (
														<img src={ImageLinks.delete} width={20} height={20} onClick={() => handle_delete(index)} />
													)}
												</div>

												<Select
													value={item.modularity.type}
													defaultOption={{
														title: 'Select Modularity',
														value: '',
													}}
													options={drop}
													handleChange={(val) => handle_select_action(val, index)}
													selected_title_styles={{ color: theme.colors.primary }}
													selected_title_props={{ semi: true }}
													containerStyles={{ marginRight: 6, width: 240, height: 35, borderColor: theme.colors.lightPurple6 }}
													menu_item_class={{ className: classes.menu_item_class }}
													menu_styles={{ width: 240 }}
												/>

												{item.modularity.type === 'call_verification' && (
													<div style={{ marginTop: 15 }}>
														<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
															Set Recipients & call attempts (Max 5 can be added)
														</Text>

														<div className={classes.sender_receiver_wrap}>
															<div className='align-items-center justify-content-between'>
																<Text semi style={{ fontSize: 12 }}>
																	Sender
																</Text>
																<div className={classes.reattempt_button}>
																	<img
																		src={ImageLinks.minus_simple_primary}
																		style={{
																			cursor: 'pointer',
																			pointerEvents: true && !true ? 'none' : 'auto',
																			visibility: item.modularity.min_call_to_originator === 0 ? 'hidden' : 'visible',
																		}}
																		onClick={() => {
																			const current_val = item.modularity.min_call_to_originator;
																			if (current_val > 0) {
																				const _reason = _.cloneDeep(reason_list);
																				_reason[index].modularity['min_call_to_originator'] = current_val - 1;
																				set_reason_list(_reason);
																			}
																		}}
																	/>
																	<Text semi style={{ color: theme.colors.primary, fontSize: 14 }}>
																		{item.modularity?.min_call_to_originator || 0}
																	</Text>
																	<img
																		src={ImageLinks.plus_simple_primary}
																		style={{
																			cursor: 'pointer',
																			pointerEvents: true && !true ? 'none' : 'auto',
																			visibility: item.modularity.min_call_to_originator === 5 ? 'hidden' : 'visible',
																		}}
																		onClick={() => {
																			const current_val = item.modularity.min_call_to_originator;
																			if (current_val > 4) {
																				toast.error('Max value can be 5', {
																					position: toast.POSITION.BOTTOM_RIGHT,
																				});
																				return;
																			}
																			const _reason = _.cloneDeep(reason_list);
																			_reason[index].modularity['min_call_to_originator'] = current_val + 1;
																			set_reason_list(_reason);
																		}}
																	/>
																</div>
															</div>

															<div className='align-items-center justify-content-between' style={{ marginTop: 14 }}>
																<Text semi style={{ fontSize: 12 }}>
																	Receiver
																</Text>
																<div className={classes.reattempt_button}>
																	<img
																		src={ImageLinks.minus_simple_primary}
																		style={{
																			cursor: 'pointer',
																			pointerEvents: true && !true ? 'none' : 'auto',
																			visibility: item.modularity.min_call_to_receiver === 0 ? 'hidden' : 'visible',
																		}}
																		onClick={() => {
																			const current_val = item.modularity.min_call_to_receiver;
																			if (current_val > 0) {
																				const _reason = _.cloneDeep(reason_list);
																				_reason[index].modularity['min_call_to_receiver'] = current_val - 1;
																				set_reason_list(_reason);
																			}
																		}}
																	/>
																	<Text semi style={{ color: theme.colors.primary, fontSize: 14 }}>
																		{item.modularity?.min_call_to_receiver || 0}
																	</Text>
																	<img
																		src={ImageLinks.plus_simple_primary}
																		style={{
																			cursor: 'pointer',
																			pointerEvents: true && !true ? 'none' : 'auto',
																			visibility: item.modularity.min_call_to_receiver === 5 ? 'hidden' : 'visible',
																		}}
																		onClick={() => {
																			const current_val = item.modularity.min_call_to_receiver;
																			if (current_val > 4) {
																				toast.error('Max value can be 5', {
																					position: toast.POSITION.BOTTOM_RIGHT,
																				});
																				return;
																			}
																			const _reason = _.cloneDeep(reason_list);
																			_reason[index].modularity['min_call_to_receiver'] = current_val + 1;
																			set_reason_list(_reason);
																		}}
																	/>
																</div>
															</div>
														</div>
													</div>
												)}
											</div>
										</div>
										{item.modularity.type === 'call_verification' && (
											<>
												<div style={{ marginTop: 16, marginBottom: 16, border: `1px solid ${theme.colors.lightPink4}` }}></div>
												<div className='align-items-center justify-content-between'>
													<div>
														<Text semi style={{ fontSize: 14 }}>
															Call Waiting Time
														</Text>
														<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, marginTop: 4 }}>
															Describe the nature of failed delivery
														</Text>
														<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
															(Max 30minutes can be added)
														</Text>
													</div>
													<div style={{ justifyContent: 'flex-end', display: 'flex' }}>
														<OutlinedTextField
															type='text'
															value={item.modularity.waiting_time / 60}
															textStyle={{ fontSize: 16, color: theme.colors.black }}
															name='waiting_time'
															onChange={(e) => {
																const _reason = _.cloneDeep(reason_list);
																const value = e.target.value;
																if (value > 30) {
																	toast.error('Max waiting time can be 30min', {
																		position: toast.POSITION.BOTTOM_RIGHT,
																	});
																	return;
																}
																_reason[index].modularity['waiting_time'] = value * 60;
																set_reason_list(_reason);
															}}
															label='Time*'
															style={{ width: '60%' }}
															InputProps={{
																endAdornment: (
																	<InputAdornment position='end'>
																		<Text medium style={{ fontSize: '12' }}>
																			minutes
																		</Text>
																	</InputAdornment>
																),
															}}
														/>
													</div>
												</div>
											</>
										)}
									</div>
								</div>
							))}
						</div>

						{reason_list.length < 5 && (
							<div className='align-items-center cursor-pointer' onClick={handle_add_reason}>
								<img src={ImageLinks.addPurple} width={16} height={16} alt='add-purple' />
								<Text semi className={classes.add_reason_text}>
									Add Reason
								</Text>
							</div>
						)}
					</div>

					<div className={classes.info_container}>
						<Text bold className={classes.info_heading}>
							Types of Verifications
						</Text>
						<div className={classes.info_box_wrap} style={{ marginTop: 14 }}>
							<img src={ImageLinks.chevron_up_solid_pink} width={30} height={30} alt='up_pink' />
							<Text semi style={{ fontSize: 14, marginTop: 4 }}>
								Call Verification
							</Text>
							<Text medium className={classes.infp_box_desc}>
								Choose highest trust and performance partners
							</Text>
						</div>

						<div className={classes.info_box_wrap} style={{ marginTop: 8 }}>
							<img src={ImageLinks.chevron_down_solid_blue} width={30} height={30} alt='up_pink' />
							<Text semi style={{ fontSize: 14, marginTop: 4 }}>
								OTP Verifications
							</Text>
							<Text medium className={classes.infp_box_desc}>
								These help ensure that at least the delivery gets done
							</Text>
						</div>

						<Text bold className={classes.info_heading} style={{ marginTop: 40 }}>
							How it will look on App
						</Text>
						<Text medium style={{ fontSize: 12, marginTop: 3 }}>
							Smart allocation takes your preferences, limits, and partner constraints to create an optimized fulfillment selection.
						</Text>

						<div className={classes.preview_wrap}>
							<div className='align-items-center justify-content-between' style={{ height: 36, paddingLeft: 12, paddingRight: 12 }}>
								<div className='align-items-center'>
									<img src={ImageLinks.chevron_right_black} width={12} height={12} style={{ transform: `rotate(180deg)` }} />
									<Text bold style={{ fontSize: 12, marginLeft: 6 }}>
										Failed Delivery
									</Text>
								</div>
								<img src={ImageLinks.help_solid_outline_purple} width={14} height={14} alt='help_purple' />
							</div>
							<div style={{ border: `1px solid ${theme.colors.lightGrey5}` }}></div>

							<div style={{ marginTop: 14, marginLeft: 18, marginRight: 18 }}>
								<Text semi style={{ fontSize: 12 }}>
									Select a reason for Package Return
								</Text>
								{_.map(_.slice(reason_list, 0, 3), (item, index) => (
									<div
										key={`reason_item_${index}`}
										className={classes.preview_item_wrap}
										style={{
											backgroundColor: index === 0 ? theme.colors.lightPurple9 : theme.colors.white,
											border: `1px solid ${index === 0 ? theme.colors.primary : theme.colors.lightGrey5}`,
										}}>
										<Text
											semi
											style={{
												fontSize: 12,
												color: index === 0 ? theme.colors.black : theme.colors.darkGrey2,
											}}>
											{item?.value || `Reason 0${index + 1}`}
										</Text>
										{index === 0 && <img src={ImageLinks.tickCircleSolid} width={14} height={14} />}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ position: 'absolute', bottom: 20, right: 30 }}>
				<Button disabled={btn_disabled} onClick={handle_update} type='round' text='Save' size='large' style={{ width: 110 }} />
			</div>
		</>
	);
};

export default FailedDelivery;
