import { makeStyles } from '@material-ui/core';
import Text from 'modules/Text';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';
import theme from 'resources/theme';

const useStyles = makeStyles(() => ({
	neutral_text: {
		color: theme.colors.darkGrey3,
		fontSize: 14,
	},
	link_text: {
		textDecoration: 'underline',
		color: theme.colors.primary,
		fontSize: 14,
		cursor: 'pointer',
	},
	wrapper: { display: 'flex', alignItems: 'center', position: 'absolute', bottom: 20 },
}));

const Footer = ({ show_login, show_help, show_auth_nav, on_auth_nav }) => {
	const classes = useStyles();
	const history = useHistory();

	const handle_auth_nav = () => {
		if (show_login) history.push(Routes.LOGIN.path);
		else history.push(Routes.LOGIN.path);
	};

	return (
		<div className={classes.wrapper}>
			{show_auth_nav && (
				<Text semi className={classes.neutral_text} onClick={handle_auth_nav}>
					{show_login ? 'Already a member? ' : 'New to Pidge? '}
					<Text component={'span'} bold className={classes.link_text} onClick={on_auth_nav}>
						{show_login ? 'Login' : 'Create Account'}
					</Text>
				</Text>
			)}

			{show_auth_nav && show_help && (
				<Text className={classes.neutral_text} style={{ marginLeft: 5, marginRight: 5 }}>
					|
				</Text>
			)}

			{show_help && (
				<Text semi className={classes.neutral_text}>
					{'Facing issues? '}
					<Text
						component={'span'}
						bold
						className={classes.link_text}
						onClick={() => {
							window.open('https://pidge.in/contact-us/', '_blank');
						}}>
						Get Support
					</Text>
				</Text>
			)}
		</div>
	);
};

export default Footer;
