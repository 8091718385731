import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import clsx from 'clsx';

import theme from 'resources/theme';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import PopPod from './components/PopPod';
import GeoLocation from './components/GeoLocation';
import Calling from './components/Calling';
import Routes from 'resources/Routes';
import api_requests from 'resources/api_requests';
import { toast } from 'react-toastify';
import BarCodes from './components/BarCodes';
import utils from 'resources/utils';
import constant from 'resources/constant';
import UndeliveredOrders from './components/UndeliveredOrders';
import FailedDelivery from './components/FailedDelivery';

const useStyles = makeStyles(() => ({
	container: {
		padding: '32px 32px 0 32px',
		display: 'flex',
		height: '100%',
		position: 'relative',
		flexDirection: 'column',
	},
	header: {
		display: 'flex',
		height: 40,
		alignItems: 'center',
	},
	heading_text: {
		fontSize: 22,
		color: 'black',
	},
	all_settings: {
		color: theme.colors.primary,
		fontSize: 14,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	body_section: { display: 'flex', marginTop: 32, flex: 1, overflowY: 'hidden' },
	content_section: {
		backgroundColor: theme.colors.white,
		borderTopLeftRadius: 50,
		flex: 1,
		padding: 30,
		overflowY: 'auto',
	},
	sidebar: { width: '20%' },
	sub_container: { paddingBottom: 20, marginTop: 10, borderRight: `2px solid ${theme.colors.lightPurple}`, height: '100%' },
	bundle_list_item: {
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.white,
		borderRadius: 8,
		marginTop: 10,
		marginRight: 16,
		border: `1px solid transparent`,
		cursor: 'pointer',
		'&:hover': { backgroundColor: theme.colors.lightPurple, border: '1px solid #492897' },
	},
	list_item: {
		display: 'flex',
		flex: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingRight: 10,
		height: 40,
	},
	sub_title: { marginLeft: 8, fontSize: 14 },
	active_class: { backgroundColor: theme.colors.lightPurple, border: '1px solid #492897' },
}));

export const SETTINGS_MENU = {
	geo_location: {
		id: 'geolocation',
		name: 'Geo Location & Fencing',
	},
	pop_pod: {
		id: 'pop_pod',
		name: 'POP & POD',
	},
	calling: {
		id: 'calling',
		name: 'Masked Calling',
	},
	barcodes: {
		id: 'barcodes',
		name: 'Barcodes',
	},
	undelivered_orders: {
		id: 'undelivered_orders',
		name: 'Undelivered Orders',
	},
	failed_delivery: {
		id: 'failed_delivery',
		name: 'Failed Delivery',
	},
};

const OrderSettings = ({ history }) => {
	const query_object = qs.parse(history.location.search.slice(1));
	const classes = useStyles();
	const [refresh, set_refresh] = useState(false);
	const [business_settings, set_business_settings] = React.useState({});
	const [active_card_id, set_active_card_id] = React.useState(query_object.type || SETTINGS_MENU.geo_location.id);

	const navigate = (id) => {
		history.push(Routes.ORDER_SETTINGS.path + `?type=${id}`);
	};

	useEffect(() => {
		api_requests.get_business_settings().then((res) => {
			set_business_settings(res.data);
		});
	}, [refresh]);

	useEffect(() => {
		if (!query_object.type) {
			return;
		}
		set_active_card_id(query_object.type);
	}, [query_object.type]);

	const handle_update_settings = (new_setting) => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.SAVE_POP_POD, new_setting);
		api_requests.update_business_settings(new_setting).then((res) => {
			set_business_settings({
				...business_settings,
				...new_setting,
			});
			toast.success(res.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			set_refresh((prev) => !prev);
		});
	};

	if (_.isEmpty(business_settings)) {
		return null;
	}

	let active_comp = null;

	switch (active_card_id) {
		case SETTINGS_MENU.geo_location.id:
			active_comp = (
				<GeoLocation geofencing={business_settings.geofencing} business_settings={business_settings} handle_update_settings={handle_update_settings} />
			);
			break;

		case SETTINGS_MENU.pop_pod.id:
			active_comp = (
				<PopPod
					modularity_logistics_pickup={business_settings.modularity_logistics_pickup}
					modularity_reverse_logistics_pickup={business_settings.modularity_reverse_logistics_pickup}
					modularity_logistics_delivery={business_settings.modularity_logistics_delivery}
					modularity_reverse_logistics_delivery={business_settings.modularity_reverse_logistics_delivery}
					modularity_middle_mile_pickup={business_settings.modularity_middle_mile_pickup}
					modularity_middle_mile_delivery={business_settings.modularity_middle_mile_delivery}
					handle_update_settings={handle_update_settings}
				/>
			);
			break;

		case SETTINGS_MENU.calling.id:
			active_comp = <Calling mask_call={business_settings.mask_call} handle_update_settings={handle_update_settings} />;
			break;

		case SETTINGS_MENU.barcodes.id:
			active_comp = <BarCodes quick_scan_code={business_settings.quick_scan_code} handle_update_settings={handle_update_settings} />;
			break;
		case SETTINGS_MENU.undelivered_orders.id:
			active_comp = <UndeliveredOrders failed_delivery={business_settings.reattempt_config} set_refresh={set_refresh} />;
			break;
		case SETTINGS_MENU.failed_delivery.id:
			active_comp = <FailedDelivery failed_reasons={business_settings.failed_delivery_reasons} set_refresh={set_refresh} />;
			break;
		default:
			break;
	}

	return (
		<div className={classes.container}>
			<div className='align-items-center'>
				<div className='page_back_btn' onClick={() => history.push(Routes.SETTINGS.path)}>
					<img src={ImageLinks.arrow_up_circle_solid} width={18} height={18} />
				</div>
				<Text semi className={'global_header_text'}>
					Order Settings
				</Text>
			</div>
			<div className={classes.body_section}>
				<div className={classes.sidebar}>
					<div className={classes.sub_container}>
						{_.map(SETTINGS_MENU, (item, key) => {
							const is_selected = active_card_id === item.id;
							return (
								<div
									className={clsx(`box-shadow ${classes.bundle_list_item}`, is_selected && classes.active_class)}
									key={`menu${key}`}
									onClick={(e) => {
										navigate(item.id);
									}}>
									<div className={classes.list_item}>
										<Text bold className={classes.sub_title}>
											{item.name}
										</Text>
										{is_selected ? (
											<img style={{ alignSelf: 'center' }} src={ImageLinks.chevron_right_pink} width={8} height={8} alt='0' />
										) : (
											<img style={{ alignSelf: 'center' }} src={ImageLinks.chevron_right_grey} width={8} height={8} alt='0' />
										)}
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className={classes.content_section}>{active_comp}</div>
			</div>
		</div>
	);
};

export default OrderSettings;
