import _ from 'lodash';
import React from 'react';
import MyDashboardEmbedComponent from './MyDashboardEmbedComponent';

function MongoCharts({ charts, token }) {
	return (
		<div style={{ display: 'flex', gap: 10, flexWrap: 'wrap', height: '85vh', overflow: 'scroll' }}>
			{token && charts.length > 0 && (
				<>
					{_.map(charts, (chart) => {
						return <MyDashboardEmbedComponent chart={chart} token={token} />;
					})}
				</>
			)}
		</div>
	);
}

export default MongoCharts;
