import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';

export const NETWORK_TYPE = [
	{
		key: 'PRIVATE',
		title: 'Private',
		description: 'Only invited members can use the network services',
		icon: ImageLinks.lock,
		is_disabled: false,
	},
	{
		key: 'PUBLIC',
		title: 'Public',
		description: 'Anyone on the Pidge platform can use the network services',
		icon: ImageLinks.globe_gray,
		is_disabled: true,
	},
];

export const RULES_SECTION = [
	{
		key: 'areas',
		title: 'Serviceable Areas',
		is_disabled: false,
	},
	{
		key: 'pricing',
		title: 'Pricing',
		is_disabled: false,
	},
	{
		key: 'surge_pricing',
		title: 'Surge Pricing',
		is_disabled: false,
	},
	{
		key: 'cancelliation_charges',
		title: 'Cancellation Charges',
		is_disabled: false,
	},
	{
		key: 'allocation',
		title: 'Allocation',
		is_disabled: false,
	},
	{
		key: 'support_functions',
		title: 'Support Functions',
		is_disabled: false,
	},
	{
		key: 'undelivered_orders',
		title: 'Undelivered Orders',
		is_disabled: false,
	},
	{
		key: 'failed_delivery',
		title: 'Failed Delivery',
		is_disabled: false,
	},

	{
		key: 'remittance',
		title: 'Remittance',
		is_disabled: true,
	},
	{
		key: 'return_orders',
		title: 'Return Orders',
		is_disabled: true,
	},
	{
		key: 'cancellation_rules',
		title: 'Cancellation Rules',
		is_disabled: true,
	},
];

export const DISPLAY_STYLE_OPTIONS = [
	{
		label: 'Upload Image',
		value: 'image',
	},
	{
		label: 'Avatar',
		value: 'avatar',
	},
];

export const AVATER_STYLE_OPTIONS = [
	{
		label: 'Icon',
		value: 'icon',
	},
	{
		label: 'Monogram',
		value: 'monogram',
		subtitle: ' (initials of Network Name)',
	},
];

export const ICON_STYLE = [
	{ key: 'rider', default_img: ImageLinks.rider_black, selected_img: ImageLinks.rider_purple, white_img: ImageLinks.rider_white },
	{ key: 'route', default_img: ImageLinks.route_z_black, selected_img: ImageLinks.route_z_purple, white_img: ImageLinks.route_z_white },
	{ key: 'polygon', default_img: ImageLinks.polygon_black, selected_img: ImageLinks.polygon_purple, white_img: ImageLinks.polygon_white },
	{ key: 'globe', default_img: ImageLinks.globe_black, selected_img: ImageLinks.globe_purple, white_img: ImageLinks.globe_white },
];

export const AVATAR_COLOR = [
	{
		key: 'pink',
		bg: theme.colors.darkPurple,
	},
	{
		key: 'blue',
		bg: theme.colors.lightBlue,
	},
	{
		key: 'green',
		bg: theme.colors.darkGreen1,
	},
	{
		key: 'orange',
		bg: theme.colors.lightOrange2,
	},
	{
		key: 'purple',
		bg: theme.colors.lightPink8,
	},
	{
		key: 'gray',
		bg: theme.colors.darkGrey2,
	},
];

export const UPPER_SECTION_STEPS = [
	{
		type: 'label',
		title: 'Upload Image',
	},
	{
		type: 'label',
		title: 'Set Network Name',
	},
	{
		type: 'label',
		title: 'Short Description',
	},
	{
		type: 'button',
		title: 'Proceed',
	},
];

export const LOWER_SECTION_STEPS = [
	{
		type: 'label',
		title: 'Set Up Profile',
	},
	{
		type: 'label',
		title: 'Define Serviceable Areas',
	},
	{
		type: 'label',
		title: 'Set Pricing critieria',
	},
	{
		type: 'label',
		title: 'Set Surge Pricing',
	},
	{
		type: 'label',
		title: 'Set Cancellation Charges',
	},
	{
		type: 'label',
		title: 'Set Allocation guidelines',
	},
	{
		type: 'label',
		title: 'Assign Support Functions',
	},
	{
		type: 'label',
		title: 'Set Undelivered Orders',
	},
	{
		type: 'label',
		title: 'Set Failed Delivery',
	},
	{
		type: 'button',
		title: 'Go Live',
		edit_title: 'Update',
	},
];

export const UPPER_SECTION_INVITE_STEPS = [
	{
		type: 'label',
		title: 'Add Participant using invite code',
	},
	{
		type: 'label',
		title: 'Define participant role',
	},
	{
		type: 'button',
		title: 'Set Rules',
	},
];
export const UPPER_SECTION_INVITE_STEPS_2 = [
	{
		type: 'label',
		title: 'Review network rules',
	},
	{
		type: 'button',
		title: 'Add Participant',
	},
];

export const ALLOW_CRITERIA_OPTION = [
	{
		label: 'Allow All',
		value: true,
	},
	{
		label: 'None',
		value: false,
	},
];

export const CREATE_OPTIONS = [
	{
		title: 'Pincode',
		key: 'pincode',
	},
	{
		title: 'Draw On Map',
		key: 'draw',
	},
];

export const PKG_VOLUME_OPTIONS = [
	{ title: '225 in3', value: '225', disabled: false },
	{ title: '450 in3', value: '450', disabled: false },
	{ title: '900 in3', value: '900', disabled: false },
	{ title: '1800 in3', value: '1800', disabled: false },
	{ title: '3600 in3', value: '3600', disabled: false },
];

export const ALLOCATION_TABS = ['Allocation', 'Route', 'Orders'];

export const ALLOCATION_CRITERIAL_OPTIONS = [
	{ title: 'Best Rider', subtitle: 'Filter top 3 suitable riders', value: 'best_cost' },
	// { title: 'Broadcast to Riders', subtitle: 'Send request to multiple riders', value: 'gig' },
	{ title: 'Available Riders', subtitle: 'Filter top 3 available riders', value: 'free_rider' },
	{ title: 'Top Rider', subtitle: 'Filters the best rider for delivery', value: 'top_riders' },
];

export const ACTION_ON_FAILED_DELIVERY = [
	{ title: 'Return to Sender', subtitle: 'Package will be sent to the sender', value: true },
	{ title: 'Reattempt', subtitle: 'If you or your supplier do not have a warehouse then order will return to sender', value: false },
];
export const SUPPORT_FUNC_TABS = ['Grievance', 'Customer', 'Rider'];

export const PRICING_RULE = {
	UNPAID: 1,
	DISTANCE: 2,
	API: 3,
	PINCODE: 4,
};

export const PRICING_OPTIONS = [
	{ title: 'Unpaid Trips', value: PRICING_RULE.UNPAID, disabled: false },
	{ title: 'Distance Based', value: PRICING_RULE.DISTANCE, disabled: false },
	{ title: 'Pincode Based', value: PRICING_RULE.PINCODE, disabled: false },
	{ title: 'API Based', value: PRICING_RULE.API, disabled: true },
];

export const PINCODE_PRICING_SECTIONS = [
	{ title: 'Pickup Pincodes', key: 'pickup' },
	{ title: 'Delivery Pincodes', key: 'drop' },
	{ title: 'Setup Rates', key: 'rates' },
];

export const SURGE_PRICING_TAB = ['Days', 'Time'];
export const SURGE_PRICING_DAYS_OF_WEEK = [
	{ name: 'Monday', value: 2 },
	{ name: 'Tuesday', value: 3 },
	{ name: 'Wednesday', value: 4 },
	{ name: 'Thursday', value: 5 },
	{ name: 'Friday', value: 6 },
	{ name: 'Saturday', value: 7 },
	{ name: 'Sunday', value: 1 },
];
