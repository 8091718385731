const Routes = {
	DASHBOARD: {
		path: '/dashboard',
		exact: true,
	},
	HOME: {
		path: '/',
		exact: true,
	},

	ALL_ORDERS: {
		path: '/all-orders',
		exact: true,
	},
	POWERED_WITH_PIDGE: {
		path: '/powered-with-pidge',
		exact: true,
	},
	CREATE_ORDER: {
		path: '/create-order',
		exact: true,
	},
	TRACKING: {
		path: '/tracking',
		exact: true,
	},
	SETTINGS: {
		path: '/settings',
		exact: true,
	},
	ORDER_SETTINGS: {
		path: '/settings/order-settings',
		exact: true,
	},
	ALL_RIDERS: {
		path: '/all-riders',
		exact: true,
	},
	ALL_ORDERS_BY_RIDER: {
		path: '/rider/:id/all-orders',
		exact: true,
	},
	GROUPING_AND_ALLOCATION: {
		path: '/grouping-and-allocation',
		exact: true,
	},
	ROSTER: {
		path: '/roster',
		exact: true,
	},
	MANGE_HUB: {
		path: '/manage-hub',
		exact: true,
	},
	MANGE_PICKUP_ADDRESS: {
		path: '/manage-pickup-address',
		exact: true,
	},

	// cash management screen start
	CASH_MANAGEMENT: {
		path: '/cash-management',
		exact: true,
	},
	CASH_MANAGEMENT_MY_OVERVIEW: {
		path: '/cash-management-my-overview',
		exact: true,
	},
	CASH_MANAGEMENT_ALL_RIDERS: {
		path: '/cash-management/all-riders',
		exact: true,
	},
	CASH_MANAGEMENT_RIDER_DETAIL: {
		path: '/cash-management/rider/:id',
		exact: true,
	},
	CASH_MANAGEMENT_ALL_ADMIN: {
		path: '/cash-management/all-admin',
		exact: true,
	},
	CASH_MANAGEMENT_ADMiN_DETAIL: {
		path: '/cash-management/admin/:id',
		exact: true,
	},
	// cash management screen end

	MY_WALLET: {
		path: '/my-wallet',
		exact: true,
	},
	COMMUNICATION_MANAGEMENT: {
		path: '/communication-management',
		exact: true,
	},
	CREATE_COMMUNICATION: {
		path: '/create-communication',
		exact: true,
	},
	USER_MANAGEMENT: {
		path: '/user-management',
		exact: true,
	},
	MY_ALERTS: {
		path: '/my-alerts',
		exact: true,
	},
	CREATE_ALERT: {
		path: '/create-alert',
		exact: true,
	},
	ZONES: {
		path: '/zones',
		exact: true,
	},
	RIDER_PROFILE: {
		path: '/rider/:id/profile',
		exact: true,
	},
	DASHBOARD: {
		path: '/dashboard',
		exact: true,
	},
	INTEGRATION_MANAGEMENT: {
		path: '/integration-management',
		exact: true,
	},
	GENERATE_TOKEN: {
		path: '/integration-management/generate-token',
		exact: true,
	},
	EDIT_TOKEN: {
		path: '/integration-management/edit-token/:id',
		exact: true,
	},
	RIDER_SETTINGS: {
		path: '/settings/delivery-executive-setting',
		exact: true,
	},
	SUPPORT: {
		path: '/support',
		exact: true,
	},
	ADVANCED_ORDER_ANALYTICS: {
		path: '/advanced-order-analytics',
		exact: true,
	},
	ADVANCED_RIDER_ANALYTICS: {
		path: '/advanced-rider-analytics',
		exact: true,
	},
	RIDER_SETTINGS: {
		path: '/settings/delivery-executive-setting',
		exact: true,
	},
	WHITELABELLING_SETTINGS: {
		path: '/settings/whitelabelling-setting',
		exact: true,
	},
	REPEAT_ORDERS: {
		path: '/repeat-orders',
		exact: true,
	},
	EDIT_RIDER: {
		path: '/rider/:id/edit',
		exact: true,
	},
	NETWORKS: {
		path: '/networks',
		exact: true,
	},
	CREATE_NETWORK: {
		path: '/create-network',
		exact: true,
	},
	INVITE_NETWORK: {
		path: '/network/:id/invite',
		exact: true,
	},
	FLEET_MANAGEMENT: {
		path: '/fleet-management',
		exact: true,
	},
	TRACE_LISTING: {
		path: '/trace-list',
		exact: true,
	},
	CHILD_BUSINESS: {
		path: '/child-business-list',
		exact: true,
	},
	SHOPIFY_AUTH: {
		path: '/shopify-auth',
		exact: true,
	},
	LOGIN: {
		path: '/login',
		exact: true,
	},

	ACCESS_DENIED: {
		path: '/access-denied',
		exact: true,
	},

	SMART_ALLOCATION: {
		path: '/settings/smart-allocation',
		exact: true,
	},

	SMART_MANIFEST: {
		path: '/settings/smart-manifest',
		exact: true,
	},
	ANALYTICS: {
		path: '/analytics',
		exact: true,
	},
};

export default Routes;
