import React, { useEffect, useState } from 'react';
import MongoCharts from './components/MongoCharts';
import api_requests from 'resources/api_requests';
import _ from 'lodash';

function Analytics() {
	const [mongo_charts, set_mongo_charts] = useState([]);
	const [mongo_token, set_mongo_token] = useState('');

	useEffect(() => {
		get_mongo_charts();
	}, []);

	const get_mongo_charts = async () => {
		try {
			const res = await api_requests.get_mongo_charts();
			set_mongo_token(_.get(res, 'data.token', ''));
			set_mongo_charts(_.get(res, 'data.items', []));
		} catch (err) {}
	};
	return <div style={{ margin: 30 }}>{mongo_charts.length > 0 && <MongoCharts charts={mongo_charts} token={mongo_token} />}</div>;
}

export default Analytics;
